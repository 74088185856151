import React from 'react'
import '../styles/app.less'

const App = () => {
	return(
		<div className='coming-soon'>
			<h1>Soon.</h1>	
		</div>
	)
}

export default App 